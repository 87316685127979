import React from 'react';

import styled from 'styled-components';
import SEO from '../components/SEO';

const NotFoundPageStyles = styled.div`
  padding: var(--padding);
  margin: var(--margin) 0;
  text-align: center;
`;

const NotFoundPage = ({ location }) => {
  return (
    <NotFoundPageStyles>
      <SEO title="404: Page introuvable" location={location} />
      <h1>404: Page introuvable</h1>
      <p>La page que vous recherchez n'existe pas.</p>
    </NotFoundPageStyles>
  );
};
export default NotFoundPage;
